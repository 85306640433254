@import 'src/style/use';

.route-profile {
	&__subnavigation {
		margin: 2rem 0;
	}

	&__body {
		&__header {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 1rem;
			margin-top: 1rem;

			.at-mobile({
				flex-direction: column;
				align-items: stretch;
			});

			&__column {
				position: relative;

				&__m-left {
					flex: 0;

					.at-mobile({
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
					});
				}

				&__m-right {
					flex: 1;
				}

				&__profile-image {
					.size(10rem);
					padding: .5rem;
					background-color: var(--page-color-accent);
					background-position: center;
					background-repeat: no-repeat;
					background-size: 150px;
					background-origin: content-box;
				}

				&__username {
					position: relative;
					.size(100%, 10rem);
					padding: .5rem;
					background-color: var(--page-color-accent);
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					align-items: flex-start;
					z-index: 1;

					.at-mobile({
						height: auto;
					});

					&__value {
						font-size: 4rem;

						.at-mobile({
							font-size: 2rem;
						});
					}

					&__id {
						font-size: .8rem;
						opacity: .75;
					}
				}

				&__username-shadow {
					display: block;
					position: absolute;
					.size(100%, 10rem);
					top: 0;
					left: 0;
					right: 0;
					background-color: var(--page-color-shadow);
					clip-path: polygon(0% 120%, 120% -40%, 120% 120%);
					transform: translateX(.5rem) rotate(1deg);
					z-index: 0;
					filter: blur(.5rem);
					overflow: visible;

					.at-mobile({
						display: none;
					});
				}
			}
		}
	}

	&__social-networks {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem;

		.at-mobile({
			grid-template-columns: repeat(1, 1fr);
		})
	}

	&__giveaways {
		display: grid;
  		grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
		gap: 1rem;

		&__item {
			position: relative;
			border: 2px solid var(--page-color-accent);
			min-width: 20rem;
			height: 15rem;
			outline: 0 solid var(--page-color-accent);
			outline-offset: .25rem;
			transition: outline .1s linear;
			overflow: hidden;

			&:hover {
				outline: 2px solid var(--page-color-accent);

				.route-profile {
					&__giveaways__item {
						&__media {
							transform: scale(1.1);
						}

						&__promocode {
							opacity: 1;
						}
					}
				}
			}

			&__media {
				position: absolute;
				.size(200px);
				top: 50%;
				left: 50%;
				margin: -100px 0 0 -100px;
				transform: scale(1);
				transition: transform .2s cubic-bezier(0.34, 1.56, 0.64, 1);
			}

			&__title {
				position: absolute;
				top: .5rem;
				left: .5rem;
				right: .5rem;
				text-align: center;
				padding: .5rem 1rem;
				backdrop-filter: blur(5px);

				&:before {
					content: '';
					position: absolute;
					inset: 0;
					background-color: var(--page-color-accent);
					opacity: .5;
					z-index: 0;
				}

				&__text {
					position: relative;
					font-family: var(--page-font-family-accent);
					z-index: 1;
				}
			}

			&__promocode {
				position: absolute;
				left: .5rem;
				right: .5rem;
				bottom: .5rem;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
				gap: .5rem;
				padding: 1rem;
				backdrop-filter: blur(5px);
				opacity: 0;
				transition: opacity .2s linear;

				&:before {
					content: '';
					position: absolute;
					inset: 0;
					background-color: var(--page-color-accent);
					opacity: .25;
					z-index: 0;
				}

				&__title {
					position: relative;
					font-size: .8rem;
					z-index: 1;
				}

				&__value {
					position: relative;
					font-family: var(--page-font-family-mono);
					font-weight: 600;
					font-size: 1.75rem;
					z-index: 1;
				}

				&__button {
					position: relative;
					background-color: transparent;
					border: 2px solid var(--page-color-text);
					color: var(--page-color-text);
					font-size: .8rem;
					padding: .5rem .75rem;
					z-index: 1;
					text-transform: lowercase;
					cursor: pointer;
					transition: color .2s linear, background-color .2s linear;
					overflow: hidden;

					&:hover, &:focus {
						background-color: var(--page-color-text);
						color: var(--page-color-accent);
					}

					&__m-status {
						.route-profile__giveaways__item__promocode__button {
							&__label {
								transform: scale(0);
								opacity: 0;
							}
						}
					}

					&__m-status&__m-done {
						.route-profile__giveaways__item__promocode__button__status__m-done {
							transform: translateY(-50%);
							opacity: 1;
						}
					}

					&__m-status&__m-fail {
						.route-profile__giveaways__item__promocode__button__status__m-fail {
							transform: translateY(-50%);
							opacity: 1;
						}
					}

					&__label {
						position: relative;
						transform: scale(1);
						opacity: 1;
						transition: transform .1s linear, opacity .1s linear;
					}

					&__status {
						position: absolute;
						left: 0;
						right: 0;
						top: 50%;
						transform: translateY(-50%);
						opacity: 0;
						transition: transform .1s linear, opacity .1s linear;

						&__m-done {
							transform: translateY(-3rem);
						}

						&__m-fail {
							transform: translateY(3rem);
						}
					}
				}
			}
		}
	}
}
