@import 'src/style/use';

.route-profile-active-season {
	&__statistics {
		&__row {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			gap: 2rem;
			padding: 2rem;

			&__image {
				.size(6rem);
				border-radius: 3rem;
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
			}

			&__data {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: stretch;
				gap: 1rem;
				flex: 1;

				&__username {
					font-size: 1.25rem;
					font-weight: 600;
				}

				&__values {
					flex: 1;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					gap: 2rem;

					&__item {
						&__title {
							font-size: .8rem;
							opacity: .5;
						}

						&__number {
							font-size: 1.5rem;
							font-weight: 600;

							&__m-position {
								background-color: var(--page-color-accent);
							}
						}
					}
				}
			}
		}
	}

	&__battle-pass {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: stretch;
		gap: 2rem;

		&__item {
			padding: 1rem;

			&__rewards {
				margin-bottom: 2rem;

				&__list {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: stretch;

					&__item {
						padding: .25rem 1rem .25rem 3rem;
						font-size: 1.25rem;
						font-weight: 600;

						&:before {
							content: '+ ';
							color: var(--page-color-accent);
						}
					}
				}
			}

			&__check {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-transform: uppercase;

				&:before {
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					bottom: -1rem;
					.size(100%, 12.5rem);
					z-index: 0;
					opacity: 0;
					transition: opacity .2s linear;
				}

				&__m-waiting {
					&:before {
						background-image: radial-gradient(ellipse at bottom, var(--page-color-status-waiting) 0%, transparent 50%);
						opacity: .15;
					}
				}

				&__m-success {
					&:before {
						background-image: radial-gradient(ellipse at bottom, var(--page-color-status-success) 0%, transparent 50%);
						opacity: .15;
					}
				}

				&__content {
					position: relative;
					z-index: 1;
				}
			}
		}

		&__status {
			color: var(--page-color-text);
			text-transform: uppercase;
			white-space: nowrap;
		}

		&__multiple-actions {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			gap: .5rem;
		}
	}

	&__giveaways {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: stretch;
		gap: 1rem;

		&__grid {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			gap: 1rem;
		}

		&__reveal {
			padding: 2rem;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
