@import 'src/style/use';

.route-leaderboard {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	gap: 1rem;
	height: 100%;
	padding-block-start: .5rem;

	&__leaderboard-selector {

	}

	&__gifts {
		margin-block-start: -1rem;

		.at-mobile({
			margin-block-start: 0;
		});

		&__title {
			display: block;

			.at-mobile({
				display: none;
			});
		}
	}

	&__subnavigation {
		margin-block: 2rem;
	}
}
