@import "src/style/use";

.route-profile-account {
	&__contacts {
		padding: 1rem;
		&__form {
			display: flex;
			flex-direction: column;

			justify-content: flex-start;
			align-items: stretch;
			gap: .25rem;

			&__row {
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				align-items: stretch;
				gap: .25rem;

				.at-mobile({
					flex-direction: column;
					justify-content: flex-start;
					align-items: stretch;
				});

				&__field {
					position: relative;
					padding: 1.25rem 2rem .5rem;
					color: var(--page-color-text);
					background-color: var(--page-color-lightest);
					border: none;
					width: 100%;
					border-bottom: 1px solid var(--page-color-lightest);

					&:has(&__input:focus) {
						.with-gradient-border-bottom();
					}

					&__input {
						position: relative;
						background: transparent;
						border: none;
						color: var(--page-color-text);
						width: 100%;
						z-index: 0;

						&::placeholder {
							color: var(--page-color-text);
							opacity: 0;
							transition: opacity .1s linear;
						}

						&:focus {
							outline: none;

							&::placeholder {
								opacity: .5;
							}
						}
					}

					&__input:not(:placeholder-shown) + &__label,
					&__input:focus + &__label {
						opacity: .25;
						transform: translateY(-115%) scale(.6);
					}

					&__label {
						position: absolute;
						left: 2rem;
						bottom: .75rem;
						opacity: 1;
						transform: translateY(0) scale(1);
						transform-origin: bottom left;
						transition: opacity .1s linear, transform .1s linear;
						text-transform: uppercase;
						z-index: 1;
					}
				}
			}

			&__actions {
				padding-block-start: .5rem;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-end;
			}
		}
	}

	&__social-networks {
		padding: 1rem;

		&__label {
			color: var(--page-color-text);
			text-transform: uppercase;
			white-space: nowrap;
		}
	}
}
