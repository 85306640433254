@import 'src/style/use';

.route-wall-auth {
	position: absolute;
	inset: 0;

	&:before {
		content: '!';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-family: var(--page-font-family-mono);
		font-size: 40rem;
		color: var(--page-color-accent);
		opacity: .25;

		.at-mobile({
			font-size: 25rem;
		})
	}

	&__wall {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap: 2rem;

		&__warning {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			gap: .75rem;

			text-align: center;

			&__title {
				font-family: var(--page-font-family-accent);
				font-size: 4rem;

				.at-mobile({
					font-size: 3rem;
				});
			}

			&__description {

			}
		}

		&__button {
			position: relative;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			gap: .75rem;
			padding: 1rem 1.5rem;
			border: 2px solid var(--page-color-accent);
			outline: 0 solid var(--page-color-accent);
			outline-offset: .5rem;
			text-decoration: none;
			transition: outline .1s linear;

			&:before {
				content: '';
				position: absolute;
				inset: 0;
				background-color: var(--page-color-accent);
				clip-path: polygon(0% 100%, 0% 125%, 50% 100%, 100% 125%, 100% 100%);
				transition: clip-path .1s linear;
				opacity: .75;
			}

			&:hover, &:focus {
				outline: 2px solid var(--page-color-accent);

				&:before {
					clip-path: polygon(0% 100%, 0% 0%, 50% -25%, 100% 0%, 100% 100%);
				}
			}

			&__icon {
				position: relative;
				z-index: 1;

				& > svg {
					.size(2rem);
					margin-top: .5rem;
				}
			}

			&__label {
				position: relative;
				font-size: 1.5rem;
				text-transform: uppercase;
				line-height: 2rem;
				white-space: nowrap;
				z-index: 1;
			}
		}
	}
}
