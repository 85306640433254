.route-leaderboard-schedule {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	gap: 1rem;

	&__block {
		&__date {
			font-weight: 600;
			text-transform: uppercase;
			padding: 1rem 0 .5rem 2.5rem;
		}

		&__table {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: stretch;
			gap: .5rem;
			padding: 1rem;

			&__header {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				gap: .5rem;

				&__cell {
					padding: 0 1rem;
					opacity: .5;

					&&__m-activity {
						flex: 1;
					}

					&&__m-period {
						flex: 1;
					}

					&&__m-description {
						flex: 2;
					}
				}
			}

			&__body {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: stretch;
				gap: .5rem;

				&__row {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: stretch;
					gap: .5rem;

					&__cell {
						padding: 1rem;
						background-color: var(--page-color-lightest);

						&&__m-activity {
							flex: 1;
						}

						&&__m-period {
							flex: 1;
						}

						&&__m-description {
							flex: 2;

							p, ul {
								margin: .5rem 0;

								&:first-child {
									margin-top: 0;
								}

								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}
